.acc-parentDashboard .custom-card{
   margin-bottom: 0 !important;
} 

.acc-parentDashboard  .card{
  border-radius: 0 !important;
}

.acc-parentDashboard .table-head{
  border-top: 1px solid #bfbfbf;
  border-bottom: 1px solid #bfbfbf;
  padding: 0 20px;
}

.acc-parentDashboard .table-head h6{
  margin: 0 !important;
  padding: 20px;
}

.acc-parentDashboard > div > div > .card > .card-body{
  padding: 20px;
  background: #fff !important;
}

.acc-parentDashboard .ant-collapse{
  background: #fff !important;
}

.acc-parent-content{
  padding:  20px !important;
}

.acc-parentDashboard .ant-collapse{
  width: 100% !important;
}

.acc-parentDashboard .course{
  border: 1px solid #bfbfbf !important;
  overflow: hidden;
  border-radius: 6px;
}

.acc-parentDashboard .course table{
  border: 1px solid #bfbfbf !important;
}

.acc-parentDashboard .course th{
  text-align: left;
  border-right: 1px solid #bfbfbf !important;
  border-bottom: 1px solid #bfbfbf !important;
  padding: 0 10px;
  vertical-align: middle;
  height: 40px;
}

.acc-parentDashboard .course td{
  text-align: left;
  border-right: 1px solid #bfbfbf !important;
  border-bottom: 1px solid #bfbfbf !important;
  padding: 0 10px;
  vertical-align: middle;
  height: 40px;
}

.acc-parentDashboard .course .ant-table-thead tr:first-child{
  display: none !important;
}

.acc-parentDashboard  .assignment-tasks-header{
  text-align: left;
}

.acc-parentDashboard .user-status table tbody tr:last-child td {
  border-bottom: 0 solid #bfbfbf !important;
}

.acc-parentDashboard .course  .ant-collapse{
  border: 0 !important;
}

.acc-parentDashboard  .ant-collapse-arrow{
  position: absolute !important;
  bottom: 0 !important;
  top: auto !important;
  left: 3px !important;
  padding: 10px 22px !important;
  width: 50%;
}

.acc-parent-content .user-profile .card-body{
  margin: 0 !important;
}

.course .ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header{
  padding: 20px !important;
}

.course  .ant-collapse > .ant-collapse-item > .ant-collapse-header{
  padding: 20px !important;
}

.course .ant-collapse > .ant-collapse-item {
  border: 0 !important;
}

.course-message {
  font-size: 20px;
  color: #212529;
}

.acc-parent-profile-label {
  display: flex;
  justify-content: center;
  padding: 100px;
}